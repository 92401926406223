export default function List({ items }: { items: Array<string> }) {
  return (
    <ul className="landing-page__list">
      {items.map((item, i) => (
        <li key={i} className="landing-page__list-item">
          {item}
        </li>
      ))}
    </ul>
  );
}
