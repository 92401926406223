import React from 'react';

import Heading from '~/components/common/Heading';
import { OPENSEA_COLLECTION_URL } from '~/constants';

const Cta = () => {
  return (
    <section className="join">
      <div className="join__content">
        <Heading
          level="2"
          className="landing-page__hd-amarante type-center mb-space-xs"
        >
          Join the Battle
          <br />
          for Peace Today
        </Heading>
        <div className="button-row">
          <a className="button--pixel" href={OPENSEA_COLLECTION_URL}>
            GET YOUR WARRIOR ON OPENSEA
          </a>
        </div>
      </div>
      <div className="join__media">
        <img
          className="join__media"
          src="/img/join-the-battle.png"
          alt="Join the Battle for Peace Today"
        />
      </div>
    </section>
  );
};

export default Cta;
