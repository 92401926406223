export default function SectionNew({
  children,
  ...attrs
}: {
  children: React.ReactNode;
} & any) {
  return (
    <section
      className={`section ${attrs.className || ''}`}
    >
      <div className={`section__container ${attrs.containerClassName || ''}`}>
        {children}
      </div>
    </section>
  );
}
