import { useState } from 'react';

export default function Video({
  id,
  covers,
  title,
}: {
  id: string;
  covers: Array<string>;
  title: string;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const url = isPlaying ?
    `https://www.youtube.com/embed/${id}?autoplay=1` :
    `https://www.youtube.com/embed/${id}`;

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="video-player" onClick={togglePlay}>
      <div
        className={`video-player__cover ${
          isPlaying ? 'video-player__cover--hidden' : ''
        }`}
      >
        <img
          src={`/img/${covers[0]}`}
          srcSet={`/img/${covers[0]} 1x, /img/${covers[1]} 2x`}
          alt={title}
        />
        <svg
          width="83"
          height="94"
          viewBox="0 0 83 94"
          fill="none"
          className="video-player__icon"
        >
          <path
            d="M0.223858 5.65715C0.22386 1.80814 4.39052 -0.59748 7.72386 1.32702L79.7141 42.8906C83.0475 44.8151 83.0475 49.6264 79.7141 51.5509L7.72386 93.1145C4.39052 95.039 0.223862 92.6334 0.22386 88.7844L0.223858 5.65715Z"
            fill="#FFF"
          />
        </svg>
      </div>
      <iframe
        width="853"
        height="480"
        src={url}
        className="iframe-video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        title={title}
      />
    </div>
  );
}
