import React from 'react';

import Paragrah from '~/components/common/Paragrah';
import SectionNew from '~/components/hero/LandingPage/SectionNew';

const Notes = ({ title, note }: {title?: string; note: string}) => {
  return (
    <SectionNew className="section--flush" containerClassName="section__container--disclaimer">
      {(title &&
        title
      )}
      <Paragrah>{note}</Paragrah>
    </SectionNew>
  );
};

export default Notes;
