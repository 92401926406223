export default function Section({
  children,
  rightImage,
  leftImage,
  ...attrs
}: {
  children: React.ReactNode;
  rightImage: React.ReactNode | undefined;
  leftImage: React.ReactNode | undefined;
} & any) {
  const hasImage = !!(rightImage || leftImage);
  return (
    <section
      {...attrs}
      className={`landing-page__section ${attrs.className || ''}`}
    >
      {hasImage && (
        <div className="landing-page__section__image landing-page__section__image--left">
          {leftImage}
        </div>
      )}
      <div className="landing-page__section-content">{children}</div>
      {hasImage && (
        <div className="landing-page__section__image landing-page__section__image--right">
          {rightImage}
        </div>
      )}
    </section>
  );
}
