import React from 'react';

import Heading from '~/components/common/Heading';
import Logo from '~/components/common/Logo';
import Section from '~/components/hero/LandingPage/Section';

const Hero = () => {
  return (
    <>
      <Section className="landing-page__section--narrow landing-page__section--hero">
        <Logo filename="logo_white.svg" alt="Peacefall Logo"/>
        <div className="hero-banner hero-banner--landing">
          <div className="hero-banner__item">
            <img src="/img/warriors/badboy.gif" alt="Badboy" />
          </div>
          <div className="hero-banner__item">
            <img src="/img/warriors/karate.gif" alt="Karate" />
          </div>
          <div className="hero-banner__item">
            <img src="/img/warriors/tank.gif" alt="Tank" />
          </div>
          <div className="hero-banner__item">
            <img src="/img/warriors/naginata.gif" alt="Naginata" />
          </div>
          <div className="hero-banner__item">
            <img src="/img/warriors/athlete.gif" alt="Athlete" />
          </div>
          <div className="hero-banner__item hero-banner__item--flip">
            <img src="/img/ninjya-ALL0.gif" alt="Ninjya" />
          </div>
          <div className="hero-banner__item hero-banner__item--flip">
            <img src="/img/warriors/yakuza.gif" alt="Yakuza" />
          </div>
          <div className="hero-banner__item hero-banner__item--flip">
            <img src="/img/warriors/samurai.gif" alt="Samurai" />
          </div>
          <div className="hero-banner__item hero-banner__item--flip">
            <img src="/img/warriors/girl.gif" alt="Girl" />
          </div>
        </div>
      </Section>
      <Section className="landing-page__section--narrow landing-page__section--about">
        <Heading level="2" className="landing-page__hero-text">
          NFT Warriors hand-drawn by Koji Ogata, legendary artist behind Double Dragon, battling it out on the Ethereum blockchain.
        </Heading>
      </Section>
    </>
  );
};

export default Hero;
