import Link from 'next/link';

import { TOURNAMENTS } from '~/constants';


export const TournamentReward = () => {
  const activeTournament = Object.values(TOURNAMENTS).find((t) => t.isActive);

  return (
    <div className="tourney-card tourney-card--red">
      <h3 className="tourney-card__heading">
        <strong>Grand Prize</strong> → 0.5 ETH
      </h3>
      <div className="tourney-card__button">
        <Link
          href={`/tournaments/${activeTournament?.id}`}
          passHref>
          <a className="button tourney-card__button">
            View current tournament
          </a>
        </Link>
      </div>
      <div className="tourney-card__media">
        <img
          src="/img/tournament/ethereum-eth.svg"
          alt="Peacefall Tournament grand prize: ethereum"
        />
      </div>
      <div className="tourney-card__content-bottom">
        <div>
          <h3 className="tourney-card__sub-heading">2nd Place</h3>
          <p className="tourney-card__prize-name">
            → 0.2 ETH
          </p>
        </div>

        <div>
          <h3 className="tourney-card__sub-heading">3rd-4th Place</h3>
          <p className="tourney-card__prize-name">
            → 0.1 ETH
          </p>
        </div>

        <div>
          <h3 className="tourney-card__sub-heading">5th-8th Place</h3>
          <p className="tourney-card__prize-name">→ 0.05 ETH</p>
        </div>
      </div>
      <div className="tourney-card__bg" aria-hidden="true"></div>
    </div>
  );
};
