export default function Paragrah({
  children,
  ...attrs
}: { children: React.ReactNode } & any) {
  return (
    <p
      {...attrs}
      className={`landing-page__paragraph ${attrs.className || ''}`}
    >
      {children}
    </p>
  );
}
