import React from 'react';

import Heading from '~/components/common/Heading';
import Video from '~/components/common/Video';
import SectionNew from '~/components/hero/LandingPage/SectionNew';

const About = () => {
  return (
    <SectionNew containerClassName="section__container--narrow">
      <Heading
        level="2"
        className="landing-page__hd-amarante type-center mb-space-xs"
      >
        About Peacefall
      </Heading>
      <p className="landing-page__inter type-center mb-space-l">
        Part game. Part social experiment.
      </p>
      <Video
        id="Ztr5KfRgMEE"
        covers={['about-video-cover.png', 'about-video-cover@2x.png']}
        title="Reveal"
      />
    </SectionNew>
  );
};

export default About;
