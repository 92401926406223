import React from 'react';

import Heading from '~/components/common/Heading';
import SectionNew from '~/components/hero/LandingPage/SectionNew';

const Story = () => {
  return (
    <SectionNew className="section--top" containerClassName="section__container--narrow">
      <Heading level="2" className="landing-page__hero-text mb-space-xs">
        A meditation on the nature of peace.
      </Heading>

      <p className="landing-page__meditation mb-space-m">
        Peacefall begins at a time when the people of Earth have managed to achieve a global peace. They are at one with themselves and each other. The warring syndicates that wreaked havoc on society at the end of the 21st century have disbanded. Life is good.
      </p>

      <p className="landing-page__meditation">
        But, as our NFT holders will discover post-minting, all good things come to an end. And though the syndicates may have laid their weapons down, they didn’t forget where they put them.
      </p>
    </SectionNew>
  );
};

export default Story;
