import Link from 'next/link';
import React from 'react';

import Heading from '~/components/common/Heading';
import { TournamentReward } from '~/components/tournament/Reward';


const Tournament = () => {
  return (
    <>
      <Heading
        level="2"
        className="landing-page__hd-amarante type-center mb-space-l"
      >
        Tournaments every three weeks
      </Heading>

      <TournamentReward />

      <div className="button-row">
        <Link href="/tournaments">
          <a className="button--pixel button--pixel--red">View Tournaments</a>
        </Link>
      </div>
    </>
  );
};

export default Tournament;
