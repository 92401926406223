import React from 'react';

import Heading from '~/components/common/Heading';
import Paragrah from '~/components/common/Paragrah';
import SectionNew from '~/components/hero/LandingPage/SectionNew';

const Faq = () => {
  return (
    <SectionNew className="landing-page__section--faq">
      <Heading
        level="2"
        className="landing-page__h2 landing-page__h2--amarante"
      >
        FAQ
      </Heading>
      <div className="faq">
        <div className="faq__item">
          <Paragrah className="question">
            How many Warriors were minted?
          </Paragrah>
          <Paragrah className="answer">We minted out 8,192 Warriors!</Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">How were Warriors generated?</Paragrah>
          <Paragrah className="answer">
            To keep things fair, we generated the Warriors before minting and
            only revealed after mint to avoid bots / rarity snipers.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            Will the artist, Koji Ogata, receive a cut of sales?
          </Paragrah>
          <Paragrah className="answer">
            Yep! In addition, he was paid his rate for all illustration work.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            What rights do I have to my artwork?
          </Paragrah>
          <Paragrah className="answer">
            You are free to do whatever you like with your Warriors for as long
            as you retain ownership.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            When does the first battle begin?
          </Paragrah>
          <Paragrah className="answer">
            The first battle will begin three weeks after the characters are
            revealed.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            How long do we have to select our attack?
          </Paragrah>
          <div className="answer">
            <Paragrah>
              You will have 72 hours to select an attack before the winner is
              revealed. In the first three rounds, you&apos;ll see your opponent
              as soon as that window opens up.{' '}
            </Paragrah>
            <Paragrah>
              During Life or Death rounds (round 4 and on), opponents will not
              be pre-revealed.
            </Paragrah>
          </div>
        </div>
        <div className="faq__item">
          <Paragrah className="question">How does HP affect gameplay?</Paragrah>
          <Paragrah className="answer">
            HP only comes into play in the event of a tie (same attack selected
            on both sides), in which the Warrior with greater HP wins.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            When does that fighting stop?
          </Paragrah>
          <Paragrah className="answer">
            That is up to you. As the game unfolds you will have the opportunity
            to push for peace. Our goal is perpetual peace. When that will
            happen is difficult to predict as it will require collaboration
            amongst the players.
          </Paragrah>
        </div>
        <div className="faq__item">
          <Paragrah className="question">
            What happens after the battle?
          </Paragrah>
          <div className="answer">
            <Paragrah>
              We very much want this to be something that continues for a long
              time. But we&apos;ve also watched as other projects promise the
              world and basically deliver nada.
            </Paragrah>
            <Paragrah>
              We think of what we&apos;re trying to create as a longer story
              with individual chapters and this initial journey into conflict
              and then back out to peace will be chapter one.
            </Paragrah>
            <Paragrah>
              The thing we get super excited about (besides the artwork which we
              love) is the idea that these characters will each start to build
              their own stories over time and that those legends can be built
              off of in different scenarios, etc.
            </Paragrah>
          </div>
        </div>
      </div>
    </SectionNew>
  );
};

export default Faq;
