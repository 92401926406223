import Link from 'next/link';
import React from 'react';

import List from '~/components/common/List';
import Video from '~/components/common/Video';
import SectionNew from '~/components/hero/LandingPage/SectionNew';
// import Levels from '~/components/hero/Levels';

const Gameplay = () => {
  return (
    <SectionNew className="landing-page__section--gameplay">
      <div className="feature">
        <figure className="feature__media">
          <img src="/img/evolve-levels.gif" alt="NFTs that Evolve" />
        </figure>
        <div className="feature__content">
          <p className="feature__number">#1</p>
          <h3 className="feature__heading">NFTs that Evolve</h3>
          <p>
            The more your character wins in battle, the stronger they become. As
            your characters evolve, their appearance will change, they will have
            stronger weaponry, and their strength in the game will grow.
          </p>
          <p>
            Check out how our evolving NFTs look on Opensea. Example Ninja
            Example Specter
          </p>
          <Link href="/collection">
            <a className="button--pixel">View All Warriors & Levels</a>
          </Link>
        </div>
      </div>

      <div className="feature feature--rev">
        <figure className="feature__media">
          <img src="/img/artist-process.gif" alt="A Legendary Artist" />
        </figure>
        <div className="feature__content">
          <p className="feature__number">#2</p>
          <h3 className="feature__heading">A Legendary Artist</h3>
          <p>
            The Warriors are hand-created by Koji Ogata, one of the artists on
            the original Double Dragon.
          </p>
          <p>
            Yes, that Double Dragon. He’s brought his magic touch and connection
            to classic side scrollers to this project.
          </p>
        </div>
      </div>

      <div className="feature">
        <figure className="feature__media">
          <Video
            id="BDnbNKIdneU"
            covers={['dual-rarity-cover.png', 'dual-rarity-cover@2x.png']}
            title="Dual Rarity"
          />
        </figure>
        <div className="feature__content">
          <p className="feature__number">#3</p>
          <h3 className="feature__heading">Dual Rarity</h3>

          <p>
            Most NFT collections have one type of rarity: the kind you get at
            mint. Your NFT will never be any more rare than what you get at the
            reveal. Peacefall has two types of rarity: Minted & Earned. Earned
            rarity comes from your performance in battle. Your warrior’s rarity
            and value improves based on decisions you make.
          </p>
          <Link href="/leaderboard">
            <a className="button--pixel">View Our Custom Leaderboard</a>
          </Link>
        </div>
      </div>

      <div className="feature feature--rev feature--clouds">
        <div className="clouds-wrapper clouds-wrapper--landing">
          <div className="clouds clouds--back">
            <img src="/img/clouds-back.png" alt="Clouds" />
          </div>
          <div className="clouds clouds--front">
            <img src="/img/clouds-front.png" alt="Clouds" />
          </div>
        </div>
        <figure className="feature__media">
          <img
            src="/img/fk-pixel-logo.png"
            srcSet="/img/fk-pixel-logo.png 1x, /img/fk-pixel-logo@2x.png 2x"
            alt="FK Logo"
          />
        </figure>
        <div className="feature__content">
          <p className="feature__number">#4</p>
          <h3 className="feature__heading">
            Fully Doxxed Team with 15 years experience.
          </h3>

          <p>
            Peacefall was created by{' '}
            <Link href="https://fictivekin.com/" passHref>
              Fictive Kin
            </Link>
            , a digital product studio based in Brooklyn, NY.
          </p>

          <List
            items={[
              'Clients include Sweetgreen, NY Times, Palantir, Microsoft, TripAdvisor and National Geographic.',
              'Organizers of the wildly popular Web 2 conference, Brooklyn Beta.',
              'Authors of an early article on decentralized content.',
            ]}
          />

          <p>
            The Peacefall team is dedicated full-time & in it for the long-haul.
          </p>
        </div>
      </div>
      {/* <Levels /> */}
    </SectionNew>
  );
};

export default Gameplay;
