import type { NextPage } from 'next';

import LandingPageAbout from '~/components/hero/LandingPage/About';
import LandingPageCta from '~/components/hero/LandingPage/Cta';
import LandingFaq from '~/components/hero/LandingPage/Faq';
import LandingPageGameplay from '~/components/hero/LandingPage/Gameplay';
import LandingPageHero from '~/components/hero/LandingPage/Hero';
import LandingNote from '~/components/hero/LandingPage/Notes';
import LandingPageStory from '~/components/hero/LandingPage/Story';
import LandingPageTournament from '~/components/hero/LandingPage/Tournament';
import MainNav from '~/components/navbar/MainNav';

const Home: NextPage = () => {
  return (
    <>
      <div className="landing-page">
        <header className="landing-page__header">
          <MainNav showConnectWallet={false} />
        </header>
        <LandingPageHero />
        <LandingPageTournament />
        <LandingPageAbout />
        <LandingPageGameplay />
        {/* <LandingPageArtist /> */}
        {/* <LandingPageTeam /> */}
        <LandingPageStory />
        <LandingPageCta />
        <LandingFaq />
        <LandingNote note="DOUBLE DRAGON is a registered trademark of its owner. We are not affiliated with, sponsored by, or associated with the owner of the DOUBLE DRAGON trademark." />
      </div>
    </>
  );
};

export default Home;
